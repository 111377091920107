import HexController from 'controllers/hex_controller'
import { i18nTrnsl } from 'actions/helpers'

export default class extends HexController {
  static targets = ['licenseExpiresAt', 'trial', 'submitBtn']

  connect() {
    this.updateLicenseExpiresAtField();
  }

  updateLicenseExpiresAtField() {
    if (this.hasTrialTarget && this.hasLicenseExpiresAtTarget) {
      if (this.trialTarget.checked) {
        this.licenseExpiresAtTarget.required = 'required';
      } else {
        this.licenseExpiresAtTarget.removeAttribute('required')
      }
      this.updateSubmitBtnStatus();
    }
  }

  updateSubmitBtnStatus() {
    if (this.hasTrialTarget && this.hasLicenseExpiresAtTarget && this.hasSubmitBtnTarget) {
      const licenseExpiresAtValue = this.licenseExpiresAtTarget.value || '';
      this.submitBtnTarget.disabled = this.trialTarget.checked && !licenseExpiresAtValue.trim().length
    }
  }

  trialDidChange(_event) {
    this.updateLicenseExpiresAtField();
    if (this.hasTrialTarget && this.hasLicenseExpiresAtTarget && this.trialTarget.checked) {
      const licenseExpiresAtValue = this.licenseExpiresAtTarget.value || ''
      if (this.trialTarget.checked && !licenseExpiresAtValue.trim().length) {
        this.licenseExpiresAtTarget.focus();
      }
    }
  }

  aiDidChange(event) {
    if (event.target.checked) {
      this.element.querySelectorAll('.ai-field').forEach(field => {
        field.classList.remove('hidden');
      });
    } else {
      this.element.querySelectorAll('.ai-field').forEach(field => {
        field.classList.add('hidden');
      });
    }
  }
}
